<template>
  <div>
    <v-container>
      <v-row no-gutters align="start">
        <v-breadcrumbs
          :items="breadcrumbs"
          class="pa-0 pb-2"
          divider=">"
        ></v-breadcrumbs>
        <v-spacer></v-spacer>
        <v-btn
          small
          depressed
          :to="{ name: 'module-arnprior-pool-arnprior-pool-bookings' }"
          ><v-icon left>mdi-arrow-left</v-icon>Back to Bookings</v-btn
        >
      </v-row>
      <v-row align="center">
        <v-col class="pr-12">
          <h1>
            {{ pluralString(booking.order.customer.full_name) }} Glamping Pod
            Booking
          </h1>
        </v-col>
        <v-col cols="auto">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                depressed
                color="blue lighten-4 blue--text"
                v-on="on"
                @click="$refs.bookingDialog.open(booking)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit Booking</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="4">
          <v-card class="mb-4">
            <v-toolbar flat dark dense color="accent">
              <v-toolbar-title>Booking Info</v-toolbar-title>
              <div class="spacer"></div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    class="mr-2"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    color="red lighten-4 red--text"
                    v-on="on"
                  >
                    <v-icon x-small>mdi-archive</v-icon>
                  </v-btn>
                </template>
                <span>Archive</span>
              </v-tooltip>
            </v-toolbar>
            <v-simple-table>
              <tbody>
                <tr>
                  <th>Total Cost</th>
                  <td>Discount</td>
                  <td>Amount Paid</td>
                  <td>Amount Due</td>
                </tr>
                <tr>
                  <td>331.00</td>
                  <td>- 50.00</td>
                  <td>106</td>
                  <td>175</td>
                </tr>
              </tbody>
            </v-simple-table>

            <v-data-table
              :headers="paidTableHeaders"
              :items="paid"
              class="mt-4"
            >
            </v-data-table>
          </v-card>

          <v-card>
            <v-toolbar flat dark dense color="accent">Basic Details</v-toolbar>
            <div class="spacer"></div>
            <v-divider></v-divider>
            <v-simple-table>
              <tbody>
                <tr>
                  <th>Full Name</th>
                  <td>{{ booking.order.customer.full_name }}</td>
                </tr>
                <tr>
                  <th>Address</th>
                  <td>
                    {{ booking.order.billing_address.line_1 }}<br />
                    {{ booking.order.billing_address.line_2 }}
                    {{ booking.order.billing_address.city }}<br />
                    {{ booking.order.billing_address.postcode }}
                  </td>
                </tr>
                <tr>
                  <th>Email</th>
                  <td>{{ booking.order.customer.email }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </v-col>

        <v-col cols="8">
          <v-card class="mb-4">
            <v-toolbar flat dark dense color="accent">
              <v-toolbar-title>Glamping Pod</v-toolbar-title>
              <div class="spacer"></div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    class="mr-2"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    color="red lighten-4 red--text"
                    v-on="on"
                    @click="deleteExtra(item)"
                  >
                    <v-icon x-small>mdi-archive</v-icon>
                  </v-btn>
                </template>
                <span>Archive</span>
              </v-tooltip>
            </v-toolbar>
            <v-divider></v-divider>
            <v-simple-table>
              <tbody>
                <tr>
                  <th>Name</th>
                  <th># of Persons</th>
                  <th>Check In</th>
                  <th>Check Out</th>
                  <th>Pod</th>
                  <th># of Adults</th>
                  <th># of Kids</th>
                  <th># of Infants</th>
                </tr>
                <tr>
                  <td>{{ booking.order.customer.full_name }}</td>
                  <td>{{ booking.booking_is.total_attendees }}</td>
                  <td>{{ booking.start_date }}</td>
                  <td>{{ booking.end_date }}</td>
                  <td>{{ booking.booking_is.pod.name }}</td>
                  <td>{{ booking.booking_is.total_adults }}</td>
                  <td>{{ booking.booking_is.total_kids }}</td>
                  <td>{{ booking.booking_is.total_infants }}</td>
                </tr>
              </tbody>
            </v-simple-table>
            <div class="spacer"></div>

            <v-simple-table>
              <tbody>
                <tr>
                  <th>Total Cost</th>
                  <th>Amount Paid</th>
                  <th>Amount Due</th>
                </tr>
                <tr>
                  <td>{{ booking.order.total }}</td>
                  <td>{{ booking.order.total }}</td>
                  <td>{{ booking.order.total }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>

          <v-card class="mb-4">
            <v-toolbar flat dark dense color="accent">
              <v-toolbar-title>Swim</v-toolbar-title>
              <div class="spacer"></div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    class="mr-2"
                    @click="$refs.swimBookingDialog.open()"
                  >
                    <v-icon x-small>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>View Order</span>
              </v-tooltip>
            </v-toolbar>
            <v-divider></v-divider>
            <v-data-table
              :headers="tableHeaders"
              :items="swim"
              class="mt-4"
              no-data-text="No Swimming Bookings found"
            >
              <template v-slot:item.booking_type="{ item }">
                <v-chip
                  label
                  small
                  color="blue white--text"
                  v-if="item.booking_type === 'booked-swim'"
                  >Booked Swim</v-chip
                >
                <v-chip
                  label
                  small
                  color="amber white--text"
                  v-if="item.booking_type === 'party'"
                  >Pool Party</v-chip
                >
              </template>

              <template v-slot:item.booking_time="{ item }">
                {{ item.formatted_dates.start_time }} -
                {{ item.formatted_dates.end_time }}
              </template>
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="blue lighten-4 blue--text"
                      v-on="on"
                      class="mr-2"
                      @click="$refs.swimBookingDialog.open(item)"
                    >
                      <v-icon x-small>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit Swimming</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="red lighten-4 red--text"
                      v-on="on"
                      @click="deleteExtra(item)"
                    >
                      <v-icon x-small>mdi-archive</v-icon>
                    </v-btn>
                  </template>
                  <span>Archive</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card>

          <v-card class="mb-4">
            <v-toolbar flat dark dense color="accent">
              <v-toolbar-title>Swim</v-toolbar-title>
              <div class="spacer"></div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    class="mr-2"
                    @click="$refs.swimBookingDialog.open()"
                  >
                    <v-icon x-small>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Add Swimming</span>
              </v-tooltip>
            </v-toolbar>
            <v-divider></v-divider>
            <v-data-table
              :headers="swimTableHeaders"
              :items="swim"
              class="mt-4"
              no-data-text="No Swimming Bookings found"
            >
              <template v-slot:item.booking_time="{ item }">
                {{ item.formatted_dates.start_time }} -
                {{ item.formatted_dates.end_time }}
              </template>

              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="blue lighten-4 blue--text"
                      v-on="on"
                      class="mr-2"
                      @click="$refs.swimBookingDialog.open(item)"
                    >
                      <v-icon x-small>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit Swimming</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="red lighten-4 red--text"
                      v-on="on"
                      @click="deleteExtra(item)"
                    >
                      <v-icon x-small>mdi-archive</v-icon>
                    </v-btn>
                  </template>
                  <span>Archive</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card>

          <v-card class="mb-4">
            <v-toolbar flat dark dense color="accent">
              <v-toolbar-title>Extras</v-toolbar-title>
              <div class="spacer"></div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    class="mr-2"
                    @click="$refs.extraDialog.open()"
                  >
                    <v-icon x-small>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Add Extras</span>
              </v-tooltip>
            </v-toolbar>
            <v-divider></v-divider>
            <v-data-table
              :headers="extrasHeaders"
              :items="booking.extras"
              class="mt-4"
              no-data-text="No extras found"
            >
              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="blue lighten-4 blue--text"
                      v-on="on"
                      class="mr-2"
                      @click="$refs.extraDialog.open(item)"
                    >
                      <v-icon x-small>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit extra?</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="red lighten-4 red--text"
                      v-on="on"
                      @click="deleteExtra(item)"
                    >
                      <v-icon x-small>mdi-archive</v-icon>
                    </v-btn>
                  </template>
                  <span>Archive</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card>

          <v-card class="mb-4">
            <v-toolbar flat dark dense color="accent">
              <v-toolbar-title>Party Pod</v-toolbar-title>
              <div class="spacer"></div>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    class="mr-2"
                  >
                    <v-icon x-small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>View Order</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    x-small
                    depressed
                    color="red lighten-4 red--text"
                    v-on="on"
                  >
                    <v-icon x-small>mdi-archive</v-icon>
                  </v-btn>
                </template>
                <span>Archive</span>
              </v-tooltip>
            </v-toolbar>
            <v-divider></v-divider>

            <v-row>
              <v-col cols="7">
                <v-simple-table>
                  <tbody>
                    <tr>
                      <th>Date</th>
                      <th>Start Time</th>
                      <th>End Time</th>
                      <th># of Attendees</th>
                      <th>Cost</th>
                    </tr>
                    <tr>
                      <!-- <td>{{ booking.party.formatted_dates.start_date }}</td>
                      <td>{{ booking.party.formatted_dates.start_time }}</td>
                      <td>{{ booking.party.formatted_dates.end_time }}</td>
                      <td>{{ booking.party.total_attendees }}</td>
                      <td>{{ booking.party.formatted_dates.start_date }}</td>-->
                    </tr>
                  </tbody>
                </v-simple-table>

                <v-simple-table>
                  <tbody>
                    <tr>
                      <th>Type</th>
                      <th>Organisation Name</th>
                    </tr>
                    <tr>
                      <!-- <td>
                        <v-chip
                          label
                          small
                          color="blue white--text"
                          v-if="booking.party.type === 'birthday'"
                          >Birthday Party</v-chip
                        >
                        <v-chip
                          label
                          small
                          color="amber white--text"
                          v-if="booking.party.type === 'corporate'"
                          >Corporate</v-chip
                        >
                      </td>
                      <td>{{ booking.party.total_attendees }}</td>-->
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-col>
              <v-col cols="5">
                <v-simple-table>
                  <tbody>
                    <tr>
                      <th>Event description</th>
                    </tr>
                    <tr>
                      <td>Description Here</td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <booking-dialog ref="bookingDialog" />
    <swim-booking-dialog ref="swimBookingDialog" />
    <extra-dialog ref="extraDialog" />

    <v-dialog v-model="deleteDialog.open" max-width="400px">
      <v-card>
        <v-card-title class="headline">Delete </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-5">
          Are you sure you want to delete this extra?
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="closeDelete()"
            >No, Cancel</v-btn
          >
          <v-btn
            color="accent"
            text
            @click="confirmDelete()"
            :loading="deleteDialog.loading"
            >Yes, Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import BookingDialog from "./components/BookingDialog";
import SwimBookingDialog from "/src/modules/arnprior/views/swimming/bookings/components/BookingDialog";
import ExtraDialog from "./components/ExtraDialog";

export default {
  components: {
    BookingDialog,
    SwimBookingDialog,
    ExtraDialog,
  },

  data() {
    return {
      deleteDialog: {
        open: false,
        name: "",
        loading: false,
      },
      breadcrumbs: [
        {
          text: "Glamping Pods",
          disabled: true,
        },
        {
          text: "Bookings",
          disabled: false,
          to: { name: "module-arnprior-pods-arnprior-pod-bookings" },
          exact: true,
        },
      ],
      tableHeaders: [
        { text: "Date", value: "formatted_dates.start_date" },
        { text: "Booking Time", value: "booking_time" },
        { text: "# of Swimmers", value: "total_attendees" },
        { text: "Cost", value: "order.total" },
        { text: "Type", value: "booking_type" },
        { text: "Adults", value: "total_adults" },
        { text: "Kids", value: "total_kids" },
        { text: "Infants", value: "total_infants" },
        { text: "Babies", value: "total_babies" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],

      swimTableHeaders: [
        { text: "Date", value: "formatted_dates.start_date" },
        { text: "Booking Time", value: "booking_time" },
        { text: "# of Swimmers", value: "total_attendees" },
        { text: "Cost", value: "order.total" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],

      paidTableHeaders: [
        { text: "Date", value: "date" },
        { text: "Type", value: "type" },
        { text: "Paid", value: "paid" },
      ],

      extrasHeaders: [
        { text: "Product", value: "extra.name" },
        { text: "Price", value: "extra.price" },
        { text: "Quantity", value: "quantity" },
        { text: "Cost", value: "cost" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],

      paid: [
        {
          date: "05/09/2022",
          type: "Online",
          paid: "17.00",
        },
        {
          date: "18/09/2022",
          type: "Cash",
          paid: "14.00",
        },
        {
          date: "22/09/2022",
          type: "Phone",
          paid: "75.00",
        },
      ],
    };
  },

  methods: {
    deleteExtra: function (extra) {
      this.deleteDialog.open = true;
      this.deleteDialog.name = extra.name;
    },
    closeDelete: function () {
      this.deleteDialog = {
        open: false,
        name: "",
        loading: false,
      };
    },
    confirmDelete: function () {
      this.deleteDialog.loading = false;
    },
  },

  computed: {
    booking() {
      return this.$store.getters["arnprior/bookingsStore/get"];
    },

    bookings() {
      return this.$store.getters["arnprior/bookingsStore/allGlamping"];
    },

    swim() {
      return this.$store.getters["arnprior/bookingsStore/allBookedSwims"];
    },
  },

  created() {
    this.breadcrumbs.push({
      text: this.booking.order.customer.full_name,
      disabled: true,
    });
  },
};
</script>
